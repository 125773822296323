<template>
    <v-container>
      <!-- Première carte pour les pays -->
      <v-card class="pa-4 mb-4">
        <v-card-title>Pays</v-card-title>
        <v-card-text>
        <v-select
          v-model="selectedCountry"
          :items="pays"
          item-text="name"  
          item-value="id"    
          label="Sélectionnez un pays"
          @change="fetchDepartments"  
        ></v-select>
      </v-card-text>
      </v-card>
  
      <!-- Deuxième carte pour les départements -->
      <!-- <v-card class="pa-4">
        <v-card-title>Départements</v-card-title>
        <v-btn @click="addDepartments">Ajouter les départements</v-btn>
      </v-card> -->
      <v-card class="pa-4">
      <v-card-title>Départements</v-card-title>
      <v-card-text>
      <strong>Prix initial :</strong>
      <v-text-field v-model="initialDelivery" label="Entrez le prix initial "></v-text-field>
      <v-btn type="submit" color="info" class="submit-btn" @click="updateAllDeliveries">Enregistrer</v-btn></v-card-text>
      <!-- Tableau pour afficher les départements -->
      <v-data-table
        :headers="tableHeaders"
        :items="departments"
        class="mt-4"
        item-key="departmentId"
      >
      <template v-slot:item.delivery="{ item }">
        <v-row align="center">
            <v-col cols="auto">
            <v-text-field
                v-model="item.delivery"
                @keyup.enter="updateDelivery(item)"
                style="width: 100px;" 
            ></v-text-field>
            </v-col>
            <v-col cols="auto">
            <v-btn type="submit" color="info" class="submit-btn"  @click="updateDelivery(item)">Modifier</v-btn>
            </v-col>
        </v-row>
</template>

      
      </v-data-table>
      
    </v-card>
    </v-container>
  </template>
  
  <script>
  import axios from 'axios';
  import Constant from "@/utils/constants";
  import cities from '../cities.json';
  
  export default {
    data() {
    return {
        library: {
            id: null,
        },

    selectedCountry: 1,
      initialDelivery: 0,
      pays: [],  // Liste  pays
      departments: [],  // Liste  départements
      tableHeaders: [
        
        { text: "Nom  département", value: "departmentName" },
        { text: "Code  département", value: "departmentCode" },
        { text: "Frais livraison", value: "delivery" },

      ],  
    };
  },
    created() {
      this.fetchPays();
      // this.fetchDepartments();
      const storedOwnerId = localStorage.getItem('ownerId');
    if (storedOwnerId) {
      this.ownerId = parseInt(storedOwnerId, 10);
      this.fetchLibraryDetails(); // Récupérer les détails de la bibliothèque au chargement du composant
    } else {
    }

    },
    methods: {
        async fetchLibraryDetails() {
      try {
        const response = await axios.get(Constant.QORANI + `library/library/getByOwnerId/${this.ownerId}`);
        this.library = response.data;
        // console.log('Library ID:', this.library.id);  // Log the library ID
        this.fetchDepartments();

      } catch (error) {
        // console.error('Failed to fetch library details:', error);
      }
    },

        async  updateDelivery(department) {
        try {
        const payloadDelivery = {
          libraryId: this.library.id, 
          departmentId: department.departmentId, 
          delivery: department.delivery 
        };
        //  console.log(payloadDelivery)
        const response = await axios.post(Constant.QORANI+`library/library/editDepartmentLibraryDelivery`, payloadDelivery);
        // console.log('Livraison mise à jour avec succès', response.data);
        alert('Livraison mise à jour avec succès')
        await this.fetchDepartments();
      } catch (error) {
        // console.error('Échec de la mise à jour de la livraison', error);
        alert('Échec de la mise à jour de la livraison')
      }

    },
    async updateAllDeliveries() {
        
      try {
        const payloadAll = {
          libraryId:this.library.id, 
          countryId: this.selectedCountry, 
          delivery: this.initialDelivery, 
        };

        // console.log(payloadAll);
        const response = await axios.post(Constant.QORANI+`library/library/addAllDepartmentToLibrary`, payloadAll);
        // console.log('Livraison pour tous les départements mise à jour avec succès', response.data);
        alert('Livraison pour tous les départements mise à jour avec succès')
        this.initialDelivery = '';
        await this.fetchDepartments();
      } catch (error) {
        // console.error('Échec de la mise à jour de tous les départements', error);
        alert('Échec de la mise à jour de tous les départements')
      }
    },


        /*****************************************  Afficher pays  ******************************** */
async fetchPays() {
  try {
    const response = await axios.get(Constant.QORANI + `library/country/getall`);
    this.pays = response.data;

    // Log the id of each country
    // this.pays.forEach(country => {
    //   console.log('Country ID:', country.id);
    //   console.log('Country name:', country.name);
    // });
  } catch (error) {
    // console.error('Échec de la récupération des pays', error);
  }
},

       /********************************    Ajouter departeemnt en utilisant json  sans reptition  ************************************* */
    async addDepartments() {
      try {
        // Préparer les listes uniques à envoyer
        const listName = [];
        const listCode = [];
        const Departments = new Set(); //   Set  ne permet pas de stocker des éléments en double

        cities.forEach(city => {
          const departmentKey = `${city.department_name}-${city.department_number}`;
          // pour vérifier si une valeur spécifique existe déjà dans le Set
          if (!Departments.has(departmentKey)) {
            Departments.add(departmentKey);
            listName.push(city.department_name);
            listCode.push(city.department_number);
          }
        });

        // Afficher chaque élément de listName et listCode sans répétition
        // listName.forEach((name, index) => {
        //   console.log(`listName[${index}] : ${name}`);
        // });

        // listCode.forEach((code, index) => {
        //   console.log(`listCode[${index}] : ${code}`);
        // });

        // Créer le department à envoyer à l'API
        const department = {
          listName,
          listCode,
          countryId:1, 
        };

        // Envoyer la requête POST à l'API
        const response = await axios.post(`${Constant.QORANI}library/department/addMultiple`, department);
        // console.log('Départements ajoutés avec succès', response.data);
      } catch (error) {
        // console.error('Échec de l ajout des départements', error);
      }
    },
    /**************************************   récupérer les départements et  les afficher   **********************************/
    async fetchDepartments() {
      try {
        const countryId = this.selectedCountry || 1; // Default to France (id 1) if not selected
        // console.log("idcoutry",countryId);
        const response = await axios.get(`${Constant.QORANI}library/library/getListLibraryDeliveryByLibrary/${this.library.id}/AndCountry/${countryId}`);
        this.departments = response.data;
        // console.log('Départements récupérés:', response.data);
      } catch (error) {
        // console.error('Échec de la récupération des départements', error);
      }
    },

  },
};
  </script>
  
 <style scoped>
.submit-btn {
  display: block;
}
.submit-btn :hover {
  color: rgb(0, 0, 0);
}
</style>
  
 
  